<template>
  <div v-if="contents !== undefined" >
    <div class="div_title" >
      <SplitButton :model="links" @click="save" severity="danger" text >
        <img alt="geek9.kr" src="https://geek9.kr/img/logo_big.e0186dc8.jpg" style="height: 4rem;border-radius:50%;" />
      </SplitButton>
      <div>
        <span style="color:gray;float: right;">
          <Select v-model="contentType" :options="contentTypeList" optionLabel="name" placeholder="게시판유형"  />
        </span>
      </div>
    </div>
    <div class="div_title">
      <InputText v-model="title" style="width:100%;"/>
      <Button @click="toggleHtmlMode" :label="isHtmlMode ? 'TEXT' : 'HTML'" size="normal"/>
    </div>
    <div class="quill-editor-container" ref="editorContainer">
      <QuillEditor 
        @ready="onEditorReady" 
        ref="modifyContent"
        v-model:content="postData" 
        contentType="html" 
        :theme="isHtmlMode ? 'bubble' : 'snow'"
        :options="editorOptions" 
        placeholder='내용을입력하세요'
        />
    </div>
    <div class="bottom-wrapper">
      <div class="tag-input">
        <div class="input-container">
          <input v-model="description" placeholder="Description" class="input-tag" />
        </div>
      </div>
      <div class="tag-input">
        <div class="input-container" @click="focusInput" :class="{ 'is-focused': isFocused }">
          <span v-for="(tag, index) in tags" :key="index" class="tag" >
            {{ tag }}
            <button @click.stop="removeTag(index)" class="remove-tag">
              &times;
            </button>
          </span>
          <input class="input-tag" ref="input" v-model="inputValue" @keydown.enter.prevent="addTag" @keydown.backspace="handleBackspace" @focus="isFocused = true" @blur="isFocused = false" placeholder="Enter tags...">
        </div>
      </div>
      <Button label="수정하기" @click="click({type:'UPDATE_BOARD'})" raised size="small"/>
    </div>
  </div>
  <div class="ad-container">
    <Adsense
      data-ad-client="ca-pub-6699354447815429" 
      data-ad-slot="8230887469"
      data-ad-format="horizontal"
      data-full-width-responsive="true">
    </Adsense>
  </div>
</template>
<script>
import { QuillEditor } from '@vueup/vue-quill'
import Quill from 'quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import { ref, getCurrentInstance, onMounted, computed, onUnmounted } from 'vue'
import router from './router'

const Size = Quill.import('attributors/style/size')
Size.whitelist = ['8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '36px']
Quill.register(Size, true)

export default {
  mounted() {
    this.api.setPage('content')
    if( this.$route.params.idx === undefined || this.$route.params.idx === null ) router.push({ name: 'admin'})
    else {
      this.idx = Number(this.$route.params.idx)
      const type = this.$route.params.type
      if(type === 'market') console.log(type)
      else  this.search({type:'SELECT_BOARD_BY_IDX', idx:this.idx, contentType:type})
    }
    this.updateToolbarPosition()
  },  
  components: {
    QuillEditor 
  },
  data() {
    return {
      idx:-1,
      imgList:[]
    }
  },
  setup() {
    const tags = ref([])
    const description = ref(undefined)
    const contents = ref({})
    const title = ref('')
    const url = ref('')
    const content = ref('')
    const imgUrl = ref('')
    const regDate = ref('')
    const modDate = ref('')
    const { proxy } = getCurrentInstance()
    const contentType = ref(null)
    const inputValue = ref('')
    const input = ref(null)
    const isFocused = ref(false)
    const postData = ref("")
    const originContent = ref("")
    const isHtmlMode = ref(false)
    const quillInstance = ref(null)
    const modifyContent = ref(null)
    const editorContainer = ref(null)
    const isToolbarFixed = ref(false)
    const toolbar = ref(null)
    const contentTypeList = ref([
      { name: 'logistics'},
      { name: 'blog'},
      { name: 'review'},
      { name: 'market'},
      { name: 'mail'},
    ])
    const addTag = () => {
      const trimmedValue = inputValue.value.trim()
      if (trimmedValue && !tags.value.includes(trimmedValue)) {
        tags.value.push(trimmedValue)
        inputValue.value = ''
      }
    }
    const removeTag = (index) => {
      tags.value.splice(index, 1)
    }
    const handleBackspace = () => {
      if (inputValue.value === '' && tags.value.length > 0) {
        removeTag(tags.value.length - 1)
      }
    }
    const focusInput = () => {
      input.value.focus()
    }
    const updateToolbarPosition = () => {
      if (!toolbar.value || !editorContainer.value) return
      const headerHeight = 165
      const containerRect = editorContainer.value.getBoundingClientRect()
      const toolbarRect = toolbar.value.getBoundingClientRect()

      if (containerRect.top <= headerHeight && !isToolbarFixed.value) {
        isToolbarFixed.value = true
        toolbar.value.style.position = 'fixed'
        toolbar.value.style.top = `${headerHeight}px`
        toolbar.value.style.width = `${containerRect.width}px`
        editorContainer.value.style.paddingTop = `${toolbarRect.height}px`
      } else if (containerRect.top > headerHeight && isToolbarFixed.value) {
        isToolbarFixed.value = false
        toolbar.value.style.position = 'static'
        toolbar.value.style.top = 'auto'
        toolbar.value.style.width = 'auto'
        editorContainer.value.style.paddingTop = '0'
      }
    }
    onMounted(() => {
      input.value.focus()
      window.addEventListener('scroll', updateToolbarPosition)
      window.addEventListener('resize', updateToolbarPosition)
    })
    onUnmounted(() => {
      window.removeEventListener('scroll', updateToolbarPosition)
      window.removeEventListener('resize', updateToolbarPosition)
    })
    const links = [
    {
        label: '명함',
        icon: 'pi pi-user',
        command: () => {
          link('https://geek9.kr/assets/geek9.jpg')
        }
      },
      {
        label: '컨설팅',
        icon: 'pi pi-comments',
        command: () => {
          link('https://kmong.com/gig/557598')
        }
      },
      {
        label: '인스타', 
        icon: 'pi pi-instagram',
        command: () => {
          link('https://www.instagram.com/geek9geek9/')
        }
      },
      {
        label: '개인해외직구',
        icon: 'pi pi-home',
        command: () => {
          link('https://geek9.kr')
        }
      },
      {
        label: '기업해외물류',
        icon: 'pi pi-truck',
        command: () => {
          link('https://geek9.kr/biz')
        }
      }
    ]
    const editorOptions = computed(() => ({
      theme: isHtmlMode.value ? 'bubble' : 'snow',
      modules: {
        toolbar: isHtmlMode.value ? false : {
          container: [
            ['bold', 'italic', 'underline', 'strike'],
            ['image', 'imageUrl', 'link', 'video'],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'size': Size.whitelist }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'align': [] }]
          ],
          handlers: {
            imageUrl: imageUrlHandler,
            image: imageHandler
          }
        }
      }
    }))
    const imageUrlHandler = () => {
      const url = prompt('이미지 URL을 입력하세요:')
      if (url) {
        const altText = prompt('이미지에 대한 설명을 입력하세요:') || ''
        insertImage(url, altText)
      }
    }

    const insertImage = (url, altText) => {
      const quill = quillInstance.value
      const range = quill.getSelection(true)
      
      quill.insertEmbed(range.index, 'image', url, Quill.sources.USER)
      quill.setSelection(range.index + 1, Quill.sources.SILENT)
      
      // alt 텍스트 설정
      setTimeout(() => {
        const img = quill.container.querySelector(`img[src="${url}"]`)
        if (img) {
          img.setAttribute('alt', altText)
        }
      }, 0)
    }
    const toggleHtmlMode = () => {
      isHtmlMode.value = !isHtmlMode.value
      if (quillInstance.value) {
        if (isHtmlMode.value) {
          quillInstance.value.setText(quillInstance.value.root.innerHTML)
        } else {
          quillInstance.value.root.innerHTML = quillInstance.value.getText()
        }
      }
    }
    const imageHandler = () => {
      const input = document.createElement('input')
      input.setAttribute('type', 'file')
      input.setAttribute('accept', 'image/*')
      input.click()

      input.onchange = async () => {
        const file = input.files[0]
        if (!file) return
        
        const altText = prompt('이미지에 대한 설명을 입력하세요:') || ''
        if(altText === undefined || altText === '') {
          this.emitter.emit('confirm', {message: '이미지에 대한 설명을 입력해주셔야 됩니다.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
          return
        } else {
          const formData = new FormData()
          formData.append("file", file)
          formData.append('service', 'geek9')
          formData.append('pbType', 'write')
          formData.append('type', 'UPLOAD_IMG')
  
          try {
            const res = await proxy.api.axiosGql('UPLOAD_IMG', formData)
            const imgUrl = proxy.api.WSRV_POCKETBASE_URL + res.data
            proxy.imgList.push(imgUrl)
  
            const quill = modifyContent.value.getQuill()
            const range = quill.getSelection(true)
            // 이미지 삽입 전 줄바꿈
            quill.insertText(range.index, '\n', Quill.sources.USER)
            quill.setSelection(range.index + 1, Quill.sources.SILENT)
  
            // 이미지 삽입
            quill.insertEmbed(range.index + 1, 'image', imgUrl, Quill.sources.USER)
  
            // 이미지 삽입 후 줄바꿈
            quill.insertText(range.index + 2, '\n', Quill.sources.USER)
            quill.setSelection(range.index + 3, Quill.sources.SILENT)
  
            if (altText) {
              // const img = quill.container.querySelector(`img[src="${imgUrl}"]`)
              // if(img) img.setAttribute('alt', altText)
              setTimeout(() => {
                const img = quill.container.querySelector(`img[src="${imgUrl}"]`)
                if (img) img.setAttribute('alt', altText)
              }, 0)
            }
          } catch (error) {
            console.error('이미지 업로드 실패:', error)
            this.emitter.emit('confirm', {message: '이미지 업로드에 실패했습니다. 다시 시도해주세요.', header:this.api.getCookies('page'), icon:'pi pi-exclamation-triangle'})
          }
        }
      }
    }
    const onEditorReady = (quill) => {
      quillInstance.value = quill
      toolbar.value = editorContainer.value.querySelector('.ql-toolbar')
      updateToolbarPosition()
      const imageUrlBtn = document.querySelector('.ql-imageUrl')
      const svgIcon = `<svg fill="#000000" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m807.186 686.592 272.864 272.864H0v112.94h1080.05l-272.864 272.978 79.736 79.849 409.296-409.183-409.296-409.184-79.736 79.736ZM1870.419 434.69l-329.221-329.11C1509.688 74.07 1465.979 56 1421.48 56H451.773v730.612h112.94V168.941h790.584v451.762h451.762v1129.405H564.714v-508.233h-112.94v621.173H1920V554.52c0-45.176-17.619-87.754-49.58-119.83Zm-402.181-242.37 315.443 315.442h-315.443V192.319Z" fill-rule="evenodd"></path> </g></svg>`
      if (imageUrlBtn) {
        imageUrlBtn.innerHTML = svgIcon
        imageUrlBtn.title = '링크사진넣기'
      }

      const sizeSelector = document.querySelector('.ql-size')
      if (sizeSelector) {
        sizeSelector.title = '글자크기'
        const options = sizeSelector.querySelectorAll(`.ql-picker-item`)
        
        for(let i=0; i<options.length; i++) {
          let option = options[i]
          const size = Size.whitelist[i]
          if (option) option.textContent = ' - ' + size
        }
      }

      quill.on('editor-change', (eventName, ...args) => {
        if (eventName === 'selection-change') {
          const [range] = args
          if (range) {
            const format = quill.getFormat(range)
            if (format.size) sizeSelector.value = format.size
            else sizeSelector.value = ''
          }
        }
      })

      const linkButton = editorContainer.value.querySelector('.ql-link')

      if (linkButton) linkButton.title = '링크 삽입/수정'
      quill.keyboard.addBinding({ key: 'K', shortKey: true }, function() {
        this.quill.format('link', prompt('Enter link URL:'))
      })
    }
    const link = (url) => {
      let link = document.createElement('a')
      link.href = url
      link.target = '_blank'
      if(url === 'https://geek9.kr/assets/geek9.jpg') link.setAttribute('download', 'geek9.jpg')
      document.body.appendChild(link)
      link.click()
    }
    const search = async (param) => {
      const type = param.type
      await proxy.api.axiosGql(type, param)
      .then( async(res) => {
        contents.value = res.data[0]
        originContent.value = contents.value.CONTENT
        content.value = proxy.api.textCut(proxy.api.stripHtmlTags(originContent.value), 200)
        imgUrl.value = contents.value.IMG_URL
        title.value = contents.value.TITLE
        regDate.value = contents.value.REG_DATE
        modDate.value = contents.value.MOD_DATE === null ? '' : contents.value.MOD_DATE
        const foundType = contentTypeList.value.find(item => item.name === contents.value.TYPE)
        contentType.value = foundType || null
        description.value = contents.value.DESC
        tags.value = contents.value.KEYWORD.split(', ')
        url.value = 'https://contents.geek9.kr/blog/' + contents.value.IDX
        postData.value = originContent.value
        const tmpList = await proxy.api.getAllImageSrcs(originContent.value)
        tmpList.map((imgUrl) => {
          proxy.imgList.push(imgUrl)
        })
      }).catch( (err) => {
        console.log(err)
        router.push({ name: 'admin'})
      }).finally( () => {
        proxy.loading = false
      })
    }
    return {
      editorOptions, links, search, contents, title, url, imgUrl, content, regDate, modDate, contentTypeList, contentType, tags, description, removeTag, addTag, input, isFocused, focusInput, handleBackspace, inputValue, postData, originContent,
      isHtmlMode, editorContainer,
      toggleHtmlMode, modifyContent,
      onEditorReady, updateToolbarPosition
    }
  },
  methods: {
    
    async click(param) {
      const type = param.type
      if(type === 'modify') {
        console.log(type)
      } else if(type === 'UPDATE_BOARD') {
        if(confirm("수정할까요?")) {
          let date = new Date()
          const offset = 9 * 60
          const kstDate = new Date(date.getTime() + offset * 60 * 1000)
          const modDate = kstDate.toISOString().replace('Z', '+09:00')

          const formData = new FormData()
          formData.append('boardType', this.contentType.name)

          formData.append('id', this.api.getCookies('id'))
          formData.append('idx', this.idx)
          formData.append('type', type)
          formData.append('title', this.title)
          formData.append('content', this.postData)
          formData.append('desc', this.description)
          formData.append('modDate', modDate)

          let keyword = await this.api.list2String(this.tags)

          formData.append('keyword', keyword.replace(/"/g, ''))

          const src = await this.api.getFirstImageSrc(this.postData)
          if(src === null) formData.append('imgUrl',  'https://geek9.kr/assets/geek9_logo.jpg')
          else formData.append('imgUrl',  src)

          await this.api.axiosGql(type, formData)
          .then( async (res) => {
            if(res.status === 200) {
              for(let i=0; i<this.imgList.length; i++) {
                const deleteImgUrl = this.imgList[i].replace('https:','')
                if(this.postData.indexOf(deleteImgUrl) === -1) {
                  this.imgList.splice(i, 1)
                  const split = deleteImgUrl.split('/')
                  const subType = 'DELETE_IMG'
                  const formData2 = new FormData()
                  formData2.append("id", split[split.length-2])
                  formData2.append('type', subType)
                  this.api.axiosGql(subType, formData2)
                  --i
                } 
              }
              alert('수정되었습니다')
              window.location.reload()
              // this.contents = {}
              // this.search({type:'SELECT_BOARD_BY_IDX', idx:this.idx, contentType:this.contentType.name})

            } else alert('다시 시도해주세요')
          })
          .catch(err => {
            console.error("Error:", err)
          })
        }
      }
    }
  }
}
</script>
<style scoped>
  /* .write-input {
    width:80%;
    border:none;
    padding:10px;
    font-size: 20px;
  }
  .write-input:focus {
    outline:none;
  }
  .ql-editor {
    caret-color: #ff7f50;
    cursor: url('./assets/cursor.png'), auto;
    font-family: monospace;
    white-space: pre-wrap;
  } */
</style>
  